import React from "react";
import "../CSS/Product.css";
import image1 from "../Assets/image1.jpg";
import image2 from "../Assets/image2.jpg";
import image3 from "../Assets/image3.jpg";
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import LivingLinen from "../Components/LivingLinen";
import KitchenLinen from "../Components/KitchenLinen";
import DevourLinen from "../Components/DevourLinen";
import { motion } from "framer-motion";

function Product() {
  const products = [
    { title: "Living Linen", component: <LivingLinen />, img: image1 },
    { title: "Kitchen Linen", component: <KitchenLinen />, img: image2 },
    { title: "Devour Linen", component: <DevourLinen />, img: image3 },
  ];

  return (
    <div className='product-main'>
      <div className='product-top'>
        <div>
          <h3 className='product-title'>OUR PRODUCT RANGE</h3>
          <p className='product-content'>
            100% Cotton Handloom / Power loom made-ups & Fabrics of Dyed,
            Printed, Woven & Jacquard.
          </p>
        </div>
      </div>

      {/* <h3 className="product-title-two">Product Categories</h3> */}
      <div className='product-flex'>
        {products.map((data, index) => {
          return (
            <Link
              className='product-link'
              key={index}
              onClick={() => {
                modals.open({
                  title: data.title,
                  size: "85%",
                  children: data.component,
                });
              }}
            >
              <motion.div
               whileHover={{ scale: 1.1 }}
               whileTap={{ scale: 0.9 }}
               className='product-card'>
                <img src={data.img}></img>
                <h3 className='product-sub-title'>Living Linen</h3>
              </motion.div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
export default Product;
