import React from "react";
import "../CSS/About.css";

// Images
import image1 from "../Assets/About/About_image1.jpg"
import { Center, Title } from "@mantine/core";
function About() {

  // Test Para List
  const dataList = [
    `To establish the desired quality standards which are acceptable by the customers`,
    `To discover flaws or variations in raw materials and the production processes to ensure smooth and uninterrupted flow of production.`,
    `To evaluate the methods and process of production and suggest further improvements in their functioning.`,
    `To study and determine the extent of quality deviation in a product during the manufacturing process and analyse in detail the causes responsible for such deviation.`,
    `To undertake measures which are helpful in achieving the desired quality of the product.`
  ]


  return (
    <div className="about-main">
      <Center>
        <h3 className="director-name">
          About us
        </h3>
      </Center>
      <br/>
      <div className="about-testimonials">
        <div className="about-container-content">
          <h3 className="director-name">
            OUR TESTIMONIALS :
          </h3>
          <ul>
            {
              dataList.map((value, index) => {
                return (
                  <li key={index}>
                    {value}
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className="about-container-image">
          <img src={image1} />
        </div>
      </div>
      <div className="directors-main">
        <h2 className="director-main-title">JK Fashion's Directors</h2>
        <div className="director-flex">
          <div className="director-left">
            <div>
              <h3 className="director-name">John Smith</h3>
              <h4 className="director-content">Founder</h4>
            </div>
          </div>
          <div className="director-right">
            <img
              src={
                "https://thumbs.dreamstime.com/b/handsome-male-model-posing-88528667.jpg"
              }
            ></img>
          </div>
        </div>
        <div className="director-flex director-flex-two">
          <div className="director-left">
            <div>
              <h3 className="director-name">Paul Walker</h3>
              <h4 className="director-content">Managing Director</h4>
            </div>
          </div>
          <div className="director-right">
            <img
              src={
                "https://thumbs.dreamstime.com/b/portrait-male-african-american-professional-possibly-business-executive-corporate-ceo-finance-attorney-lawyer-sales-155546880.jpg"
              }
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
