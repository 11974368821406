import React from "react";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import Comments from "./Comments";
import { ReviewComments } from "../data";
const Review = () => {
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  return (
    <Carousel
      slideSize='70%'
      height={400}
      slideGap='md'
      dragFree
      withIndicators
      withControls={false}
      loop
      plugins={[autoplay.current]}
    >
      {ReviewComments.map((data, index) => {
        // console.log(data);
        return (
          <Carousel.Slide
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Comments {...data} />
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
};

export default Review;
