import {
  Text,
  Avatar,
  Group,
  Paper,
} from "@mantine/core";
import classes from "./Comments.module.css";

const Comments = (data) => {
  console.log(data);
  return (
    <Paper
      withBorder
      radius='md'
      className={classes.comment}
    >
      <Group>
        <Avatar
          src='https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png'
          alt='Jacob Warnhalter'
          radius='xl'
        />
        <div>
          <Text fz='sm'>{data.author}</Text>
          <Text
            fz='xs'
            c='dimmed'
          >
            {data.time}
          </Text>
        </div>
      </Group>
      <Text className={classes.body}>
        <div className={classes.content}>{data.comment}</div>
      </Text>
    </Paper>
  );
};

export default Comments;
