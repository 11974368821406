import React from "react";
import apron1 from "../Assets/apron1.jpg";
import apron2 from "../Assets/apron2.jpg";
import towel1 from "../Assets/towel1.jpg";
import towel2 from "../Assets/towel2.jpg";
import towel3 from "../Assets/towel3.jpg";
import holder1 from "../Assets/holder1.jpg";
import holder2 from "../Assets/holder2.jpg";
import holder3 from "../Assets/holder3.jpg";
import glove1 from "../Assets/glove1.jpg";
import glove2 from "../Assets/glove2.jpg";
import glove3 from "../Assets/glove3.jpg";

function KitchenLinen() {
  return (
    <div className="product-sub-main">
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Apron</h3>
        <div className="product-sub-img-flex">
          <img src={apron1}></img>
          <img src={apron2}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Kitchen Towel</h3>
        <div className="product-sub-img-flex">
          <img src={towel1}></img>
          <img src={towel2}></img>
          <img src={towel3}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Potholder</h3>
        <div className="product-sub-img-flex">
          <img src={holder1}></img>
          <img src={holder2}></img>
          <img src={holder3}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Gloves</h3>
        <div className="product-sub-img-flex">
          <img src={glove1}></img>
          <img src={glove2}></img>
          <img src={glove3}></img>
        </div>
      </div>
    </div>
  );
}
export default KitchenLinen;
