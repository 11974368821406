import React from "react";
import { ImLocation2 } from "react-icons/im";
import { FaMailBulk } from "react-icons/fa";
import { HiDeviceMobile } from "react-icons/hi";
import { TfiHandPointRight } from "react-icons/tfi";
import "../CSS/Footer.css";
import { Link } from "react-router-dom";
function Footer() {
  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds a smooth scrolling effect
    });
  };
  return (
    <div className='footer-main'>
      <div className='footer-flex'>
        <div className='footer-left'>
          <h3 className='footer-title'>JK Fashions</h3>
          <p className='footer-content'>
            J K FASHION , an export-oriented home textile manufacturing company,
            established seventeen past dawn of new millennium with a vision to
            be a pioneer in its sector by investing in people , technology and
            quality products.
          </p>
        </div>
        <div className='footer-two'>
          <h3 className='footer-title'>Office Address</h3>
          <div className='footer-div'>
            <div>
              <ImLocation2 className='footer-icon' />
            </div>
            <div>
              <h4 className='footer-address'>J K Fashions</h4>
              <h4 className='footer-address'>3/87-6, AARNA Web, LNS Street,</h4>
              <h4 className='footer-address'>Vadivel Nagar, Karur-02</h4>
            </div>
          </div>
        </div>
        <div className='footer-three'>
          <h3 className='footer-title'>Our Links</h3>
          <ul>
            <li onClick={scrollToTop}>
              <TfiHandPointRight />
              &nbsp;
              <Link
                to='/'
                className='footer-nav-links'
              >
                Home
              </Link>
            </li>
            <li onClick={scrollToTop}>
              <TfiHandPointRight />
              &nbsp;
              <Link
                to='/about'
                className='footer-nav-links'
              >
                About Us
              </Link>
            </li>
            <li onClick={scrollToTop}>
              <TfiHandPointRight />
              &nbsp;
              <Link
                to='/process'
                className='footer-nav-links'
              >
                Process
              </Link>
            </li>
            <li onClick={scrollToTop}>
              <TfiHandPointRight />
              &nbsp;
              <Link
                to='/product'
                className='footer-nav-links'
              >
                Products
              </Link>
            </li>
            <li onClick={scrollToTop}>
              <TfiHandPointRight />
              &nbsp;
              <Link
                to='/contact'
                className='footer-nav-links'
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer-right'>
          <h3 className='footer-title'>Get in Touch</h3>
          <div className='footer-div'>
            <div>
              <FaMailBulk className='footer-icon' />
            </div>
            <div>
              <h3 className='footer-address'>rohit@jkfashion.in</h3>
            </div>
          </div>
          <div className='footer-div'>
            <div>
              <HiDeviceMobile className='footer-icon' />
            </div>
            <div>
              <h3 className='footer-address'>79045 25998</h3>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-bottom-flex'>
        <div className='footer-btm-flex'>
          <div>
            <p className='footer-copyright'>
              @2024 JK Fashion. All Rights Reserved.
              <span>
                <a
                  href='http://interdeccaan.in/'
                  target='_blank'
                >
                  Designed by Inter Deccaan Solutions
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
