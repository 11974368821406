import React from "react";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import Home from "./Pages/Home";
import { Routes, Route } from "react-router-dom";
import Process from "./Pages/Process";
import Contact from "./Pages/Contact";
import Product from "./Pages/Product";
import LivingLinen from "./Components/LivingLinen";
import KitechnLinen from "./Components/KitchenLinen";
import DevourLinen from "./Components/DevourLinen";
import About from "./Pages/About";

function Main() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/process" element={<Process />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product" element={<Product />} />
        <Route path="/living" element={<LivingLinen />} />
        <Route path="/kitchen" element={<KitechnLinen />} />
        <Route path="/devour" element={<DevourLinen />} />
      </Routes>
      <Footer />
    </>
  );
}
export default Main;
