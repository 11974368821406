import React from "react";
import runner1 from "../Assets/runner1.jpg";
import runner2 from "../Assets/runner2.jpg";
import tablecloth1 from "../Assets/tablecloth1.jpg";
import tablecloth2 from "../Assets/tablecloth2.jpg";
import tablecloth3 from "../Assets/tablecloth3.jpg";
import placemat1 from "../Assets/placemat1.jpg";
import placemat2 from "../Assets/placemat2.jpg";
import placemat3 from "../Assets/placemat3.jpg";

function DevourLinen() {
  return (
    <div className="product-sub-main">
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Runner</h3>
        <div className="product-sub-img-flex">
          <img src={runner1}></img>
          <img src={runner2}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Table Cloth</h3>
        <div className="product-sub-img-flex">
          <img src={tablecloth1}></img>
          <img src={tablecloth2}></img>
          <img src={tablecloth3}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Placemat</h3>
        <div className="product-sub-img-flex">
          <img src={placemat2}></img>
          <img src={placemat1}></img>
          <img src={placemat3}></img>
        </div>
      </div>
    </div>
  );
}
export default DevourLinen;
