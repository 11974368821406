import React, { useState } from "react";
import "../CSS/Navbar.css";
import logo from "../Assets/Logo.jpg";
import { Link, NavLink } from "react-router-dom";

// Tabler Icon
import { Menu2, X } from "tabler-icons-react";

function Navbar() {
  // useState
  const [toggleMenu, setToggleMenu] = useState(true);
  return (
    <div className='navbar-main'>
      <div className='navbar-left'>
        <div className='df'>
          <img src={logo}></img>
          <h3 className='nav-title'>JK Fashion</h3>
        </div>
        <div className='Menu_Icon'>
          {toggleMenu ? (
            <Menu2
              size={30}
              strokeWidth={2}
              color={"black"}
              onClick={() => setToggleMenu(!toggleMenu)}
            />
          ) : (
            <X
              size={30}
              strokeWidth={2}
              color={"black"}
              onClick={() => setToggleMenu(!toggleMenu)}
            />
          )}
        </div>
      </div>
      <div className={`nav-right ${toggleMenu ? "" : "ds_none"}`}>
        <ul>
          <li onClick={() => setToggleMenu(!toggleMenu)}>
            <NavLink
              to=''
              className='nav-links'
            >
              Home
            </NavLink>
          </li>
          <li onClick={() => setToggleMenu(!toggleMenu)}>
            <NavLink
              to='/about'
              className='nav-links'
            >
              About Us
            </NavLink>
          </li>
          <li onClick={() => setToggleMenu(!toggleMenu)}>
            <NavLink
              to='/process'
              className='nav-links'
            >
              Process
            </NavLink>
          </li>
          <li onClick={() => setToggleMenu(!toggleMenu)}>
            <NavLink
              to='/product'
              className='nav-links'
            >
              Products
            </NavLink>
          </li>
          <li onClick={() => setToggleMenu(!toggleMenu)}>
            <NavLink
              to='/contact'
              className='nav-links'
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Navbar;
