import React from "react";
import home from "../Assets/home.jpg";
import "../CSS/Home.css";
import video_bg from "../Assets/background-video2.mp4";
import { GiSilverBullet } from "react-icons/gi";
import studio from "../Assets/studio.jpg";
import cert1 from "../Assets/certificate1.png";
import cert2 from "../Assets/certificate2.png";
import cert3 from "../Assets/certificate3.png";
import cert4 from "../Assets/certificate4.png";
import cert5 from "../Assets/certificate5.png";
import Review from "../Components/Review";
import { AssociateUnit } from "../data";
import { motion } from "framer-motion";

function Home() {
  return (
    <div className='home-main'>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 380,
          damping: 100,
        }}
        className='home-top'
      >
        <img src={home}></img>
      </motion.div>
      {/* Video Section */}

      <div className='home-video-section'>
        <div className='youtube-section'>
          <video
            autoPlay
            muted
            loop
            className='background-video'
          >
            <source
              src={video_bg}
              type='video/mp4'
            />
          </video>
          <div>
            <div className='content'>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                className='youtube-right'
              >
                <p>
                  J K Fashion has a manufacturing facility spanning over 40,000
                  square feet in Karur. It houses our state-of-the-art
                  production line, as well an in-house design studio. Our
                  industrial engineers work in tandem with our intricate
                  machinery to make sure the entire production process is
                  carried out without flaw.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Bottom Cards */}
      {/* Experts Section */}
      <div className='home-second-section'>
        <div className='experts-div'>
          <h3 className='expert-title'>Why and Who Work with us ?</h3>
          <p className='expert-content'>
            Best prices and MOQ We offer a smooth process assigned with a key
            account manager who ensures right quality at right time
          </p>
          <p className='expert-content'>
            We are open to Big Chain stores, wholesalers,Distributers, catalogue
            Companies and online stores
          </p>
          {/* <p className='expert-content'>
            Eirmod est stet sea tempor labore kasd tempor, no at no amet ipsum
            lorem labore. Stet dolor kasd sed labore.
          </p> */}
        </div>

        {/* Green Energy Section */}
        <div className='green-energy-main'>
          <motion.div
            initial={{ position: "relative", top: 200, opacity: 0 }}
            whileInView={{ position: "relative", top: 0, opacity: 1 }}
            className='green-left'
          >
            <h3 className='green-title'>Founder's History</h3>
            <p className='green-content'>
              Started in the early 90s as a Yarn Trader, our initial focus was
              on building the textile business, which has now become a
              significant segment. We had a dream of establishing a textile unit
              to add value to the yarn we sell. Additionally, we are engaged in
              mining blue metals, offering a financial advantage to our textile
              operations. We manufacture and supply blue metals to 90% of
              customers in our native territory.
            </p>
          </motion.div>
          <div className='green-right'>
            <img
              src={
                "https://knowledge.wharton.upenn.edu/wp-content/uploads/2015/04/2015-04-19-IGEL-banner-art.jpg"
              }
            ></img>
          </div>
        </div>

        {/* Design Studio */}
        <div className='design-studio-main'>
          <div className='design-left'>
            <img src={studio}></img>
          </div>
          <motion.div
            initial={{ position: "relative", top: 200, opacity: 0 }}
            whileInView={{ position: "relative", top: 0, opacity: 1 }}
            className='design-right'
          >
            {/* <h3 className='green-title'>In House Design Studio</h3> */}
            <p className='green-content'>
              Since 2017, "J K FASHION" has excelled in the Home Textile
              Industry. Our commitment to affordable, high-quality products sets
              us apart. Strategic sourcing allows us to offer competitive
              prices, and our growth includes a corporate office and dedicated
              factory for increased capacity. Pioneering new designs and
              emphasizing innovation, we strive to consistently provide fresh
              offerings to the market. Our goal is to be the customer's
              preferred choice through excellent service and quality.
            </p>
          </motion.div>
        </div>
        {/* Certifications */}
        <h3 className='cert-title'>Certifications</h3>
        <div className='cert-main'>
          <div className='cert-flex'>
            <div className='cert-card'>
              <img src={cert1}></img>
            </div>
            <div className='cert-card'>
              <img src={cert2}></img>
            </div>
            <div className='cert-card'>
              <img src={cert4}></img>
            </div>
            <div className='cert-card'>
              <img src={cert5}></img>
            </div>
          </div>
        </div>
      </div>

      {/* Associate Units */}

      <div className='associate-main'>
        <h3 className='associate-title'>Our Associate Units</h3>
        <div className='associate-flex'>
          {AssociateUnit.map((data, index) => {
            return (
              <div
                key={index}
                className='home-div'
              >
                <GiSilverBullet className='home-icon' />
                <div>
                  <h3 className='associate-sub-title'>{data.title}</h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className='review-main'>
        <h3 className='review-title'>Review</h3>
        <div className='review-comments'>
          <Review />
        </div>
      </div>
    </div>
  );
}
export default Home;
