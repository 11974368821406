import React, { useRef, useState } from "react";
import "../CSS/Contact.css";
import contact from "../Assets/contact.jpg";
import emailjs from "@emailjs/browser";
import "@mantine/core/styles.css";
import { notifications } from "@mantine/notifications";
import { FcApproval, FcHighPriority } from "react-icons/fc";
function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const id = notifications.show({
      loading: true,
      title: "Mail sending.....",
      message: "Please wait....",
      autoClose: false,
      withCloseButton: false,
    });

    emailjs
      .sendForm("service_p543dm4", "template_gszpdkr", form.current, {
        publicKey: "3G-GCiUTQ6B043c9f",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          notifications.update({
            id,
            color: "teal",
            title: "Successful",
            message: "Mail send successfully",
            icon: <FcApproval />,
            loading: false,
            autoClose: 2000,
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
          notifications.update({
            id,
            color: "red",
            title: "Wrong",
            message: "Something went wrong. Please try again",
            icon: <FcHighPriority />,
            loading: false,
            autoClose: 2000,
          });
        }
      );
  };

  return (
    <div className='contact-main'>
      <div
        className='contact-bg'
        // style={{ backgroundImage: `url(${contact})` }}
      >
        <div className='contact-flex'>
          <div className='contact-card'>
            <h3 className='contact-title'>Contact </h3>
            <p className='contact-content'>
              For questions and business inquiries fill out the form below and
              we will get back to you as soon as possible.
            </p>
            <form
              ref={form}
              onSubmit={sendEmail}
              className='contact-form'
            >
              <div>
                <div className='contact-input'>
                  <input
                    name='from_name'
                    type='text'
                    placeholder='First Name*'
                  />
                </div>
                <div className='contact-input'>
                  <input
                    name='user_email'
                    type='mail'
                    placeholder='Email*'
                  />
                </div>

                <div className='contact-input'>
                  <textarea
                    name='message'
                    type='text'
                    placeholder='Message'
                  />
                </div>
                <div className='contact-btn'>
                  <button
                    style={{ cursor: "pointer" }}
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
