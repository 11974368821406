import React from "react";
import "../CSS/Process.css";
import weaving from "../Assets/weaving.jpg";
function Process() {
  return (
    <div className="process-main">
      <h3 className="process-title">Our Process</h3>

      {/* Section 1 */}
      {/* <section>
        <div className="weaving-section">
          <div className="weaving-content">
            <h3 className="process-card-title">Weaving</h3>
            <p className="process-card-content">
              We have capacity to weave approximately 1 million meters of woven
              fabrics in different blends & widths with our own and conversion
              based looms. We have verity of looms in which we can weave quality
              fabric very quickly as per our buyer demand.
            </p>
          </div>
          <div className="weaving-image-container">
            <div>
              <img src={weaving}></img>
              <h3 className="image-title">Air Jet Room</h3>
            </div>
            <div>
              <img src={weaving}></img>
              <h3 className="image-title">Auto Loom</h3>
            </div>
          </div>
        </div>
      </section> */}
      {/* Section 1 End */}


      {/* Section 2 */}

      {/* <section>
        <div className="looms-section">
          <div className="loom-content">
            <h3 className="process-sub-title">Total Looms Running</h3>
            <ul>
              <li>Power Looms: 300 (can weave up to 112" width)</li>
              <li>Auto Looms: 500 (can weave upto 120" width)</li>
              <li>Sulzer/Shuttleless Loom: 120 (can weave upto 130")</li>
              <li>AIrJet: 8 (can weave upto 134")</li>
            </ul>
          </div>
          <div className="loom-image-container">
            <div>
              <img src={weaving}></img>
              <h3 className="image-title">Power Loom</h3>
            </div>
            <div>
              <img src={weaving}></img>
              <h3 className="image-title">Sulzer Loom</h3>
            </div>
          </div>
        </div>
      </section> */}


      {/* Section 2 End */}

      {/* Section 3 */}

      {/* <section>
        <div className="dyeing-section">
          <div className="dyeing-content">
            <h3 className="process-card-title">Dyeing & Printing</h3>
            <p className="process-card-content">
              We have agreement with some of the best Dyeing & Printing Units
              available in Faisalabad & Karachi to bleach and dye the fabrics.
            </p>
          </div>
          <div className="dyeing-image-container">
            <div>
              <img src={weaving}></img>
              <h3 className="image-title">Air Jet Room</h3>
            </div>
            <div>
              <img src={weaving}></img>
              <h3 className="image-title">Auto Loom</h3>
            </div>
          </div>
        </div>
      </section> */}


      {/* Section 3 End */}


      <div className="process-flex">
        <div className="process-card">
          <div className="process-card-left">
            <h3 className="process-card-title">Weaving</h3>
            <p className="process-card-content">
              We have capacity to weave approximately 1 million meters of woven
              fabrics in different blends & widths with our own and conversion
              based looms. We have verity of looms in which we can weave quality
              fabric very quickly as per our buyer demand.
            </p>
            <h3 className="process-sub-title">Total Looms Running</h3>
            <ul>
              <li>Power Looms: 300 (can weave up to 112" width)</li>
              <li>Auto Looms: 500 (can weave upto 120" width)</li>
              <li>Sulzer/Shuttleless Loom: 120 (can weave upto 130")</li>
              <li>AIrJet: 8 (can weave upto 134")</li>
            </ul>
          </div>
          <div className="process-right">
            <div className="process-right-img">
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Air Jet Room</h3>
              </div>
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Auto Loom</h3>
              </div>
            </div>
            <div className="process-right-img">
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Power Loom</h3>
              </div>
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Sulzer Loom</h3>
              </div>
            </div>
          </div>
        </div>
        {/* Second Card */}
        <div className="process-card process-card-two">
          <div className="process-card-left">
            <h3 className="process-card-title">Dyeing & Printing</h3>
            <p className="process-card-content">
              We have agreement with some of the best Dyeing & Printing Units
              available in Faisalabad & Karachi to bleach and dye the fabrics.
            </p>
            <div className="card-list-div">
              <h3 className="process-sub-title">Dyeing</h3>
              <ul>
                <li>
                  Open Jigger Disperses Dyeing / Pressure & Reactive Jigger
                  dyeing
                </li>
                <li>I.R Continues Dyeing.</li>
                <li>Reactive Thermosol Dyeing.</li>
              </ul>
            </div>
            <div className="card-list-div">
              <h3 className="process-sub-title">Printing:</h3>
              <ul>
                <li>
                  Rotary Printing under screen size 640mm 820mm 914mm available
                </li>
                <li>Panel Flat belt Printing up to 3.2meters wider</li>
              </ul>
            </div>
            <div className="card-list-div">
              <h3 className="process-sub-title">Finishing:</h3>
              <ul>
                <li>Sanforized Finish</li>
                <li>Mercerised Treatment</li>
                <li>Peach Finishing</li>
                <li>Raising of Flannel Fabric</li>
                <li>Anti Allergic Finish</li>
                <li>Waterproof Finish</li>
              </ul>
            </div>
          </div>
          <div className="process-right">
            <div className="process-right-img">
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Air Jet Room</h3>
              </div>
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Auto Loom</h3>
              </div>
            </div>
            <div className="process-right-img">
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Power Loom</h3>
              </div>
              <div>
                <img src={weaving}></img>
                <h3 className="image-title">Sulzer Loom</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Process;
