export const AssociateUnit = [
  {
    title: "Bala Blue Metals",
  },
  {
    title: "Rosera Traders",
  },
  {
    title: "Sri Balaram Traders",
  },
  {
    title: "Sri Murugavel Traders",
  },
];

export const ReviewComments = [
  {
    author: "Vishwa",
    pic: "",
    time: "12 minutes ago",
    comment:
      "EvHuB - iOS is the iOS application where users can find the nearest charging station, book a slot for charging, and station owners can add their hub locations to the map. The backend is managed using Express.js with MongoDB as the database.",
  },
  {
    author: "Vishwa",
    pic: "",
    time: "12 minutes ago",
    comment:
      "EvHuB - iOS is the iOS application where users can find the nearest charging station, book a slot for charging, and station owners can add their hub locations to the map. The backend is managed using Express.js with MongoDB as the database.",
  },
  {
    author: "Vishwa",
    pic: "",
    time: "12 minutes ago",
    comment:
      "EvHuB - iOS is the iOS application where users can find the nearest charging station, book a slot for charging, and station owners can add their hub locations to the map. The backend is managed using Express.js with MongoDB as the database.",
  },
  
];
