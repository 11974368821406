import React from "react";
import cushion1 from "../Assets/cushion1.jpg";
import cushion2 from "../Assets/cushion2.jpg";
import cushion3 from "../Assets/cushion3.jpg";
import window1 from "../Assets/window1.jpg";
import window2 from "../Assets/window2.jpg";
import window3 from "../Assets/window3.jpg";
import throws1 from "../Assets/throws1.jpg";
import throws2 from "../Assets/throws2.jpg";
import throws3 from "../Assets/throws3.jpg";
import curtain1 from "../Assets/curtain1.jpg";
import curtain2 from "../Assets/curtain2.jpg";
import curtain3 from "../Assets/curtain3.jpg";

import "../CSS/ProductSub.css";

function LivingLinen() {
  return (
    <div className="product-sub-main">
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Window Panel</h3>
        <div className="product-sub-img-flex">
          <img src={window1}></img>
          <img src={window2}></img>
          <img src={window3}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Throws</h3>
        <div className="product-sub-img-flex">
          <img src={throws1}></img>
          <img src={throws2}></img>
          <img src={throws3}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Cushion</h3>
        <div className="product-sub-img-flex">
          <img src={cushion1}></img>
          <img src={cushion2}></img>
          <img src={cushion3}></img>
        </div>
      </div>
      <div className="product-sub-div">
        <h3 className="product-sub-main-title">Shower Curtain</h3>
        <div className="product-sub-img-flex">
          <img src={curtain1}></img>
          <img src={curtain2}></img>
          <img src={curtain3}></img>
        </div>
      </div>
    </div>
  );
}
export default LivingLinen;
